import React, { useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from "react-router-dom";
import './App.css';
import AppRoutes from "./routes/AppRoutes"
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoadingBackDrop } from "tgsccl-core-mui";
import { configuration } from "tgsccl-core";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const { useApplicationSettings, ApplicationSettingsProvider } = configuration;

const App = () => {
  const { tgscclSettings: appSettings } = useApplicationSettings();
  const CLIENT_ID = appSettings.REACT_APP_CLIENT_ID;
  const ISSUER = appSettings.REACT_APP_ISSUER;
  const REDIRECT_URL = appSettings.REACT_APP_REDIRECT_URL;
  const SCOPES = appSettings.REACT_APP_SCOPES;
  const TITLE = appSettings.REACT_APP_TITLE;

  const config = {
    issuer: ISSUER,
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URL,
    scopes: SCOPES ? SCOPES.split(' ') : '',
    pkce: true
  };
  const oktaAuth = new OktaAuth(config);
  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), {
      replace: true,
    });
  };

  useEffect(() => {
    document.title = TITLE;
  }, []);

  return (

    <div className="App">
      <div className="page">
        <div className="content">
          <Security oktaAuth={oktaAuth}
            restoreOriginalUri={restoreOriginalUri}
          >
               <AppRoutes />
          </Security>
        </div>
      </div>
    </div>
  );
}

const AppWithRouterAccess = () => (
  <ApplicationSettingsProvider Loading={()=><LoadingBackDrop isOpen={true}/>} settingsLocation='/configs/applicationSettings.json'>
    <Router>
    <LocalizationProvider dateAdapter={AdapterMoment} >
      <App />
      </LocalizationProvider>
    </Router>
  </ApplicationSettingsProvider>
);

export default AppWithRouterAccess;