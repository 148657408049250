import { Route, Routes } from "react-router-dom";
import Home from './../Home';
import Private from './../Private';
import { LoginCallback } from "@okta/okta-react";
import { RequiredAuth } from "./SecureRoute";

const AppRoutes = () => {
  return (
    <Routes>
	   <Route path='/callback' element={<LoginCallback/>} />
      <Route path="/" element={<RequiredAuth/>}>
        
        <Route path="/" element={<Home/>} />
      </Route>
      <Route path="/private" element={<Private/>} />
    </Routes>
  );
};

export default AppRoutes;
