import React, { useState, useEffect, forwardRef } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import withClickOutside from "./useOutsideClick";
import { useCollapse } from 'react-collapsed';


    const Header = forwardRef(({ open, setOpen }, ref) => {

    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
    const { oktaAuth, authState } = useOktaAuth();

    const login = async () => { await oktaAuth.signInWithRedirect(); }
    const logout = async () => { await oktaAuth.signOut(); }
    const [userInfo, setUserInfo] = useState(null);


    useEffect(() => {
        if (!authState || !oktaAuth.isAuthenticated) {
            setUserInfo(null);
        } else {
            oktaAuth.getUser().then((info) => {
                setUserInfo(info);
            }).catch((err) => {
                console.error(err);
            });
        }
    }, [authState, oktaAuth]); // Update if authState changes

    return (
        <header className='headerAlign'>
            <div>
                <img className="max-"
                    src={`/assets/images/TGS_logo_white_XL.png`}
                    alt="logo" height="30" />
            </div>
            <div>

     <section ref={ref}>

        {oktaAuth.isAuthenticated && <a onClick={() => setOpen(!open)}>
            <img {...getToggleProps()} className="Header-logo navBarSec" src="/assets/images/TGSUserIcon.svg" width="40" height="30" />
        </a>}
      {open && (
         <div className="dropdown-content">
         {oktaAuth.isAuthenticated && userInfo && <span className="bCls">{userInfo.email}</span>}
         <span onClick={logout} className="cursorPointCls">Log Out</span>
     </div>
      )}
    </section>

    </div>

     </header >
    );
});

export default withClickOutside(Header);
