import React, { Component, useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import { useOktaAuth } from '@okta/okta-react';
import { SimpleExport, ExportButtonsExportCancel, ThemeProvider } from"tgsccl-export";
import { auth } from "tgsccl-core";
const { ClaimsContextProvider: ClaimsContext } = auth;


function DynamicModal(props) {
    const [searchCriteria,setSearchCriteria]=useState({});
    const { oktaAuth, authState } = useOktaAuth();
    const [userInfo, setUserInfo] = useState(null);
    const [inputValue, setInputValue] = React.useState("");
    const [commentText, setCommentText] = useState("");



    useEffect(() => {
        if (!authState || !authState.isAuthenticated) {
            setUserInfo(null);
        } else {
            oktaAuth.getUser().then((info) => {
                setUserInfo(info);
            }).catch((err) => {
                console.error(err);
            });
        }
    }, [authState, oktaAuth]);
const removeInvalueUwiFilter = (uwi) => {
        return (uwi && uwi.trim() !== "");
      }
function getSearchCriteria(){
    let statesToQuery = [];
    let statesFromSelectedCounties = [];
    let countiesToQUery = [];
    let countyVal = [];
    let reverseCountyVal = [];
    let sameStateSelect = [];
    let stateFromParent = [];
    for (let county of props.dataSelectedCounty) {
        statesFromSelectedCounties.push(county["State"]);
        countiesToQUery.push(county["value"]);
    }
    statesFromSelectedCounties = [... new Set(statesFromSelectedCounties)];
    for (let j = 0; j < props.dataSelectedOption.length; j++) {
        statesToQuery.push(props.dataSelectedOption[j].value);
    }
    statesToQuery = statesToQuery.filter((el) => !statesFromSelectedCounties.includes(el));


    for (let state of props.dataSelectedOption) {
        stateFromParent.push(state["value"]);
    }

    for (let d = 0; d < props.dataSelectedCounty.length; d++) {
        countyVal.push(props.dataSelectedCounty[d].value);
        let str = countyVal[d]
        reverseCountyVal.push(str.split(' - ').reverse().join(' - '));
        sameStateSelect.push(str.split(' - ').reverse().join(' - '))
    }

    if (stateFromParent.length > 0 && reverseCountyVal.length == 0 && props.dataSelectedTab == 'HeaderSearch') {
        setSearchCriteria({
            "States": stateFromParent
           });
    } else if (reverseCountyVal.length > 0 && stateFromParent.length == 0 && props.dataSelectedTab == 'HeaderSearch') {
        setSearchCriteria({
            "Counties": reverseCountyVal
           });
    } else if (reverseCountyVal.length > 0 && statesToQuery.length > 0 && props.dataSelectedTab == 'HeaderSearch') {
        setSearchCriteria({
             "States": statesToQuery,
             "Counties": reverseCountyVal
            });
    } else if (countiesToQUery.length > 0 && statesToQuery.length == 0 && props.dataSelectedTab == 'HeaderSearch') {
        setSearchCriteria({
            "Counties": reverseCountyVal
          });
    } else {
        setSearchCriteria({
            "States": [],
            "Counties": []
          });
    }

    if (props.dataUWiList.length > 0 && props.dataSelectedTab == 'uwlList') {
            setSearchCriteria({
                "States": [],
                "Counties": [],
                "uwiList": props.dataUWiList.filter(removeInvalueUwiFilter)
              })
        }
}


      useEffect(() => {
		getSearchCriteria();
	},[props.dataSelectedOption, props.dataSelectedCounty, props.dataUWiList, props.dataSelectedTab]);

    return (
        <>
         <ThemeProvider theme={'default'}>
            <Modal show={props.show} size="lg" centered >
              <Modal.Body style={{padding:'0px'}}>

              <ClaimsContext defaultProviderToken={oktaAuth.getAccessToken()}>
                      < SimpleExport title={'Export Options'} initialName={inputValue} userInfoData={userInfo}
                              initialDescription={commentText} productTypes={["WellProductionData"]} sourceSystemValue={"WellDataDelivery"}
                              searchCriteria={searchCriteria}
                              showScheduleSectionFlag={true}
                              Buttons={(options) => <ExportButtonsExportCancel {...options} />}
                              onCancel={props.close} 
                      />
                </ClaimsContext>


                </Modal.Body>
            </Modal>
         </ThemeProvider>
        </>
    );
}

export default DynamicModal;
