import axios from 'axios';


function postAPI(data, token, searchUrl, apiKey) {

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        'Accept': '*/*',
        'x-api-key': apiKey,
    }
    const res = axios.post(searchUrl, data, {
        headers: headers
    }).catch((err) => {
        if (err.response) {
            console.log(err.response.status);
        }
    })
    return res;

}

export { postAPI };