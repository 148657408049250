import { useOktaAuth } from '@okta/okta-react';
import { configuration } from "tgsccl-core";
import { postAPI } from './Api';

const { useApplicationSettings } = configuration;
const usePostApi=() => {
    const { tgscclSettings: appSettings } = useApplicationSettings();
    const { oktaAuth, authState } = useOktaAuth();
return (data)=> postAPI(data, oktaAuth.getAccessToken(), appSettings.REACT_APP_SEARCH_APIURL, appSettings.REACT_APP_APIKEY);
}

export default usePostApi;